@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;600;700;800;900&display=swap');

@import '~bootstrap/scss/bootstrap';

@import './variables.scss';
@import './components/navigation-bar.scss';
@import './components/home.scss';
@import './custom.scss';

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;

  height: 100%;

  color: #000000d9;
}

main,
header {
  overflow-x: hidden;
}

code {
  font-family: 'Inter', sans-serif !important;
}
