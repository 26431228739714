.demo-regist {
  &__form {
    .form-col {
      &:not(:nth-child(3)) {
        margin-bottom: 40px;
      }
      &:nth-child(3) {
        margin-bottom: 24px;
      }

      @media screen and (max-width: 575px) {
        &:not(:nth-child(3)) {
          margin-bottom: 1rem;
        }
        &:nth-child(3) {
          margin-bottom: 1rem;
        }
      }
    }
    input {
      &.form-control {
        height: 58px;
      }
    }

    textarea {
      resize: none;
    }
  }
}
